import { userSingleton } from "../../../classes/User";
import ProductsRepositoryImp from "../../../infrastructure/products/products-repository-implementation";

const repo = new ProductsRepositoryImp();

function getProducts(func) {
    const suscription = repo.get(userSingleton.uid, userSingleton.cid).subscribe(
        (data) => {
            func(data)
        },
        (error) => {
            console.error(error)
        }
    )
    return () => suscription.unsubscribe()
}

export { getProducts }
