import { useEffect, useState } from 'react';
import { getById } from '../../../../domain/usecase/buys/getById';
import ModalBtn from '../../../../moleculas/modalButton/hub/view';
import Table from '../../../../moleculas/tablas/view';
import { BsViewList } from 'react-icons/bs'; 
import { ButtonBasic } from '../../../../atomos/buttons';
import EditPurshaseItem from '../edit/view';
import './style.css';
import { editBuy } from '../../../../domain/usecase/buys/edit';
import { formatMoney } from '../../../../utils/formating';

const ListIemsCompra = ({data}) => {

    const [compra, setCompra] = useState([]);
    const [payment, setPayment] = useState([]);
    const [paymethods, setPayMethods] = useState([]);
    
    const [isDisable, setIsDisable] = useState(true);
    
    const [total, setTotal] = useState(0); 
    const [paySubtotal, setPaySubTotal] = useState(0);
     
    useEffect(() => {
        handleTotal()
        window.addEventListener('table-input-change-'+data.bill_id, (e) => {handleEditPay(e, payment)})
        window.addEventListener('list-items-compra-edit-item-'+data.bill_id, (e) => {handleEditItem(e, compra)}) 
        window.addEventListener("clean-purchase-"+data.bill_id, clean) 
        return () => {
            window.removeEventListener('table-input-change-'+data.bill_id, (e) => {handleEditPay(e, payment)})
            window.removeEventListener('list-items-compra-edit-item-'+data.bill_id, (e) => {handleEditItem(e, compra)}) 
            window.removeEventListener("clean-purchase-"+data.bill_id, clean) 
        };
    }, [compra, payment])

    function edit() {
        var edit = {
            bill_id: data.bill_id,
            compra: compra,
            payment: payment
        } 
        editBuy(edit)
    }

    function handleEditPay(e, pay) {
        e.preventDefault()
        var subTotal = total
        var aux = pay.map((item, idx) => {
            if (idx == e.detail.data.target.id) {
                item["value"] = parseFloat(e.detail.data.target.value)
                subTotal = subTotal - parseFloat(e.detail.data.target.value)
                return item
            }
            subTotal = subTotal - parseFloat(item["value"])
            return item
        })
        setPaySubTotal(subTotal)
        if (aux.length > 0) setPayment(aux)
    }

    function handleTotal() {
        var tot = 0
        compra.map((item) => { tot = tot + (parseFloat(item.value) * parseFloat(item.amount)) })
        var subtot = tot
        payment.map((item) => { subtot = subtot - parseFloat(item.value)})
        setTotal(tot)
        setPaySubTotal(subtot)
        isEditDisable(subtot)
    } 

    function handleEditItem(event, buy) { 
        var aux = buy.map((item) => {
            if (item.id === event.detail.data.row_id) {
                var product = event.detail.data.product
                item["amount"] = parseFloat(event.detail.data.amount)
                item["value"] = parseFloat(event.detail.data.value)
                item["sku"] = product.sku
                item["tax_buy_value"] = event.detail.data.tax_buy_value  
                return item
            }
            return item
        })
        console.log(aux)
        setCompra(aux)
    }

    function isEditDisable(value) {  
        setIsDisable(value <= 0 ? false : true)
    } 

    function update() {
        getById(setCompra, setPayment, setPayMethods, data.bill_id)
    }

    function clean() {
        setCompra([])
        setPayment([])
    }

    return (
        <div>
            <ModalBtn
                id={"compra-"+data.bill_id}
                title={<BsViewList/>}
                onClick={update}
                width="98vw"
                type="action"
            >
                <div className='list-compras-box'>
                    <h3>Productos</h3>
                    <Table
                        data={compra}
                        headersList={["Sku", "Valor", "Impuesto %", "Cantidad", "Editar"]}
                        excludeList={["id", "bill_id", "third_id", "buy_date", "vencimiento", "retention", "documento_compra"]}
                        formats = {{
                            value : {
                                type: "money",
                            }
                        }}
                    >
                        
                        <EditPurshaseItem></EditPurshaseItem>
                    </Table>
                    <h3>Metodos de pago</h3>
                    <p>Total {formatMoney(total)}</p>
                    <p>cambio: {formatMoney(paySubtotal)}</p>
                    <Table 
                        data={payment}
                        formats={
                            {
                                id : {
                                    type: "format",
                                    data: paymethods,
                                    key: "method"
                                },
                                value : {
                                    type: "input",
                                    key: data.bill_id,
                                }
                            }
                        }
                    ></Table>
                    <ButtonBasic style={{width:"95%", margin:"1%"}} showLoading={true} disabled={isDisable} onClick={edit}>Guardar</ButtonBasic>
                </div>
            </ModalBtn>
        </div>
    )
}

export default ListIemsCompra
