import { userSingleton } from "../../../classes/User";
import ThirdsRepositoryImp from '../../../infrastructure/thirds/thirds-repository-implementation'
import { dispatchCustomEvent } from "../../../utils/dispatch";

const repo = new ThirdsRepositoryImp();

function getThirdsById(func, id) {
    const suscription = repo.getById(userSingleton.uid, userSingleton.cid, id).subscribe(
        (response) => {
            var data = JSON.parse(response.data)
            if (data.length > 0) {
                func(data[0])
            } else {
                dispatchCustomEvent("notification-popup", {message: "No se encontro tercero", type:"warning"})
                func({})
            }

        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error consultando tercero", type:"error"})
            func({})
        }
    )
    return () => suscription.unsubscribe()
}

export { getThirdsById }
