import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";

import { auth } from "./firebase";
import { setCookie, deleteCookie } from "./app/utils/helper"
import { userSingleton } from "./app/classes/User";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {

    const [user, setUser] = useState(null);

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }
    function signUp(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }
    function logOut() {
        window.localStorage.clear()
        return signOut(auth);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
            setCookie('data', JSON.stringify(currentuser), 30)
            setUser(currentuser);
        });
        return () => {
            unsubscribe();
        };
    }, [auth]);

    return (
        <userAuthContext.Provider
            value={{ user, logIn, signUp, logOut }}
        >
            {children}
        </userAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(userAuthContext);
}
