//Custo css import
import './style.css'

//Boostrap import
import { BsFillCartCheckFill } from 'react-icons/bs';
import { FaClone } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';

import { EmptyArray } from '../../../domain/models/empty/empty'

//React import
import { useEffect, useState } from 'react';

import Table from '../../../moleculas/tablas/view';
import ButtonAction from '../../../atomos/buttons/buttonAction/view';
import NewProduct from '../newProduct/view';
import EditProduct from '../editProduct/view';
import BarcodeScanner from '../../../atomos/barcodescanner/barcodescanner';
import { getProducts } from '../../../domain/usecase/products/get-products';

function ProductsTable(props) {

    const [inventarioFull, SetInventarioFull] = useState(undefined);
    const [inventario, SetInventario] = useState(undefined);


    useEffect(() => {
        if(inventario === undefined){
            getProducts(SetInventario)
        }
        window.addEventListener('update-stock', handleUpdate);
        window.addEventListener('update-stock-sell', (e) => {handleUpdateSell(e, inventario)});
        window.addEventListener('update-stock-buy', (e) => {handleUpdateBuy(e, inventario)});
        return () => {
            window.removeEventListener('update-stock', handleUpdate);
            window.removeEventListener('update-stock-sell', (e) => {handleUpdateSell(e, inventario)});
            window.removeEventListener('update-stock-buy', (e) => {handleUpdateBuy(e, inventario)});
        };
    }, []);

    useEffect(() => {
        if (inventarioFull == undefined){
            SetInventarioFull(inventario)
        }
    }, [inventario])

    function handleUpdate(event) {
        if (event.defaultPrevented) {
            return;
        }
        event.preventDefault();
        getProducts(SetInventario)
    }

    function handleUpdateSell(event, data) {
        if (event.defaultPrevented) {
            return;
        }
        if (data === undefined) {
            return
        }
        var car = event.detail.data.car
        data.forEach((item) => {
            car.forEach((carItem) => {
                if (item.sku == carItem.sku) {
                    if (carItem.amount > item.stock) {
                        item.stock = 0
                    } else  {
                        item.stock = item.stock - carItem.amount
                    }
                }
            })
        })
        SetInventario(data)
    }

    function handleUpdateBuy(event, data) {
        if (event.defaultPrevented) {
            return;
        }
        if (data === undefined) {
            return
        }
        var car = event.detail.data.car
        data.forEach((item) => {
            car.forEach((carItem) => {
                if (item.sku == carItem.sku) {
                    item.stock = item.stock + carItem.amount
                }
            })
        })
        SetInventario(data)
    }

    function findProduct(input, data) {
        var response = data.filter((item) => {
            var tmp = item.name.toLowerCase().includes(input.toLowerCase())
            if (!tmp) {
                tmp = item.sku.toLowerCase().includes(input.toLowerCase())
            }
            if (!tmp) {
                tmp = item.barcode?.toLowerCase().includes(input.toLowerCase())
            }

            return tmp
        })
        return response
    }

    function handleSearchBar(event) {
        var input = event.target.value
        if (input === "") {
            SetInventario(inventarioFull)
        } else {
            var aux = findProduct(input, inventarioFull)
            SetInventario(aux)
            if (aux.length === 0) {
                SetInventario(EmptyArray)
            }
        }
    }

    return (
        <div>
            <BarcodeScanner data={inventarioFull} id='products'></BarcodeScanner>
            <div ref={props.ref} className='search-box'>
                <Form.Control
                    id='search-bar-products'
                    placeholder='Busca productos por sku o nombre'
                    onChange={(e) => {handleSearchBar(e)}}
                    list='lista'
                ></Form.Control>
                <datalist  id='lista'>
                    {
                        inventario?.map((item, idx) => {
                            return (
                                <option key={idx} value={item.name}>{item?.name}</option>
                            )
                        })
                    }
                </datalist>
            </div>
            <Table
                width={props.width ? props.width : "90vw"}
                emptyMessage="Aun no tienes productos"
                emptyChild={<NewProduct></NewProduct>}
                maxHeight={props.tableHeight ? props.tableHeight : "35vh"}
                headersList={
                    props.hidenBuyValue ?
                        ["sku", "Nombre", "Valor Venta",  "accion"] : ["sku", "Nombre", "Valor venta", "Valor compra", "Inventario", "accion"]}
                data={inventario ? inventario : []}
                excludeList={
                    props.hidenBuyValue ?
                        ["users_id", "buy_tax","barcode","stock", "id", "sell_tax", "sell_taxes_id", "skutmp", "buy_value", "buy_taxes_id", "auxsku", "total", "amount", "auxtax"] :
                        ["users_id", "buy_tax","barcode", "id", "sell_tax", "sell_taxes_id", "skutmp", "auxsku", "total", "amount", "buy_taxes_id", "auxtax"]
                }
                formats = { {value: { type: 'money'}, buy_value: { type: 'money' }, name: { type: 'tooltip' }} }
            >
                {props.children ? props.children : <div></div>}
                <ButtonAction hidden={props.hiddenAction ? props.hiddenAction : false} btnKey="add-product" className='action-btn'>
                    <BsFillCartCheckFill></BsFillCartCheckFill>
                </ButtonAction>
                <ButtonAction hidden={props.hiddenAction ? props.hiddenAction : false} btnKey="clone-product" className='action-btn'>
                    <FaClone></FaClone>
                </ButtonAction>
                <EditProduct hidden={props.enableEdit ? !props.enableEdit : true} btnKey="inventario-edit-item" className='action-btn'></EditProduct>
            </Table>
        </div>
    )
}


export default ProductsTable;
