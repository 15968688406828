import { userSingleton } from "../../../classes/User";
import BuysRepositoryImp from '../../../infrastructure/buys/buys-repository-implementation';
import { dispatchCustomEvent } from "../../../utils/dispatch";

const repo = new BuysRepositoryImp();

function getById(comprafunc, payFunc, methodsFunc, id) {
    const suscription = repo.getById(userSingleton.uid, userSingleton.cid, id).subscribe(
        (response) => {
            comprafunc(JSON.parse(response.data)["data"])
            payFunc(JSON.parse(response.data)["payment"])
            methodsFunc(JSON.parse(response.data)["paymethod"])
        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error consultando compra", type:"error"})
        }

    )
    return () => suscription.unsubscribe()
}

export { getById }
