import { useEffect, useState } from 'react';
import { listCompras } from '../../../../domain/usecase/buys/get';
import ModalBtn from '../../../../moleculas/modalButton/hub/view';
import Table from '../../../../moleculas/tablas/view'; 
import ShowThird from '../../../../thirds/organismos/showThird/view';
import ListIemsCompra from '../listItemsCompra/view';
import { ButtonAction } from '../../../../atomos/buttons';
import { deleteBuy } from '../../../../domain/usecase/buys/delete';
import { BiTrash } from 'react-icons/bi';
import ConfirmPopUp from '../../../../shared/atomos/confirm/popup'; 

const ListCompras = () => {

    const [compras, setCompras] = useState([]);

    useEffect(() => {
        window.addEventListener('reload-list-compras', update);
        window.addEventListener('delete-compra', handleDelete);
        window.addEventListener('btn-delete-compra', handleDelete);
        return () => {
            window.removeEventListener('reload-list-compras', update);
            window.removeEventListener('delete-compra', handleDelete);
            window.removeEventListener('btn-delete-compra', handleDelete);
        };
    }, [])

    function update() {
        setCompras([])
        listCompras(setCompras)
    }

    function handleDelete(event) {  
        deleteBuy(event.detail.data.bill_id)
    }

    return (
        <div>
            <ModalBtn
                id="list-compras"
                title="Compras"
                onClick={update}
                width="98vw"
            >
                <ConfirmPopUp msg='Deseas eliminar ?' eventKey='delete-compra'/>
                <Table
                    excludeList={["bill_id"]}
                    headersList={["Proveedor", "Documento", "Total", "Fecha Compra" , "Fecha vencimiento", "Ver"]}
                    formats = {{
                        third_id : {
                            type: "custom",
                            children: <ShowThird/>
                        },
                        buy_date : {
                            type: "timestamp"
                        },
                        vencimiento : {
                            type: "timestamp"
                        },
                        value : {
                            type: "money"
                        }
                    }}
                    data={compras}
                >
                    <ListIemsCompra></ListIemsCompra>
                    <ButtonAction enableLoadingAnimation={true} btnKey='notification-confirm-delete-compra'><BiTrash/></ButtonAction>
                </Table>
            </ModalBtn>
        </div>
    )
}

export default ListCompras
